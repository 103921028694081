export function resolveLp(pathname) {
  const lpIndex = pathname.indexOf("lp");
  if (lpIndex !== -1) {
    const lpSubstring = pathname.substring(lpIndex);
    const regex = /lp(\d+)/;
    const match = lpSubstring.match(regex);
    if (match !== null) {
      return match[0];
    }
  }
  return null;
}
