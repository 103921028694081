import React, { useState } from "react";
import {
  ButtonHolder,
  LinkHolderMobile,
  NavMobileContainer,
  Links,
  AncerLink,
  FeatureHolder,
  FeatureItem,
  StyledLinkDropdown,
} from "./NavbarMobileStyled";
import { Button } from "../../ReusableCommponents/Button";
import { StyledLink } from "../Navbar/NavbarStlyled";
import Icon1 from "../Navbar/Icons/create-convert.svg";
import Icon2 from "../Navbar/Icons/merge-split.svg";
import Icon3 from "../Navbar/Icons/annotate-markup.svg";
import Icon4 from "../Navbar/Icons/optimize-secure.svg";

const NavbarMobile = ({ ToggleNavbarHandler }) => {
  const [openDropdown, setOpenDropdown] = useState(false);

  const TogleDropdownHandler = () => {
    setOpenDropdown(!openDropdown);
  };

  return (
    <NavMobileContainer>
      <LinkHolderMobile>
        <Links>
          <div onClick={TogleDropdownHandler}>
            <StyledLink>Features</StyledLink>
            <FeatureHolder open={openDropdown}>
              <FeatureItem>
                <Icon1 />
                <StyledLinkDropdown
                  to="/create-convert/"
                  onClick={ToggleNavbarHandler}
                >
                  Create and Convert
                </StyledLinkDropdown>
              </FeatureItem>
              <FeatureItem>
                <Icon2 />
                <StyledLinkDropdown
                  to="/merge-split/"
                  onClick={ToggleNavbarHandler}
                >
                  Merge and Split
                </StyledLinkDropdown>
              </FeatureItem>
              <FeatureItem>
                <Icon3 />
                <StyledLinkDropdown
                  to="/annotate-markup/"
                  onClick={ToggleNavbarHandler}
                >
                  Annotate and Markup
                </StyledLinkDropdown>
              </FeatureItem>
              <FeatureItem>
                <Icon4 />
                <StyledLinkDropdown
                  to="/optimize-secure/"
                  onClick={ToggleNavbarHandler}
                >
                  Optimize & Secure
                </StyledLinkDropdown>
              </FeatureItem>
            </FeatureHolder>
          </div>

          <div>
            <StyledLink to="/" onClick={ToggleNavbarHandler}>
              Compare
            </StyledLink>
          </div>
          <div>
            <StyledLink to="/" onClick={ToggleNavbarHandler}>
              Resources
            </StyledLink>
          </div>
        </Links>
        <ButtonHolder>
          <AncerLink
            href="https://sites.fastspring.com/pdfwriter10/product/nixpdfwriter?referrer=27579844948740486,,"
            // target="_blank"
            rel="noopener noreferrer"
            className="tracker"
          >
            <Button width={"100%"}>Buy Now</Button>
          </AncerLink>
        </ButtonHolder>
      </LinkHolderMobile>
    </NavMobileContainer>
  );
};

export default NavbarMobile;
