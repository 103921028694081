exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-annotate-markup-jsx": () => import("./../../../src/pages/annotate-markup.jsx" /* webpackChunkName: "component---src-pages-annotate-markup-jsx" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-compare-js": () => import("./../../../src/pages/compare.js" /* webpackChunkName: "component---src-pages-compare-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-create-convert-jsx": () => import("./../../../src/pages/create-convert.jsx" /* webpackChunkName: "component---src-pages-create-convert-jsx" */),
  "component---src-pages-dpa-js": () => import("./../../../src/pages/dpa.js" /* webpackChunkName: "component---src-pages-dpa-js" */),
  "component---src-pages-eula-js": () => import("./../../../src/pages/eula.js" /* webpackChunkName: "component---src-pages-eula-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-installation-instructions-js": () => import("./../../../src/pages/installation-instructions.js" /* webpackChunkName: "component---src-pages-installation-instructions-js" */),
  "component---src-pages-installed-js": () => import("./../../../src/pages/installed.js" /* webpackChunkName: "component---src-pages-installed-js" */),
  "component---src-pages-lp-1-annotate-markup-jsx": () => import("./../../../src/pages/lp1/annotate-markup.jsx" /* webpackChunkName: "component---src-pages-lp-1-annotate-markup-jsx" */),
  "component---src-pages-lp-1-compare-js": () => import("./../../../src/pages/lp1/compare.js" /* webpackChunkName: "component---src-pages-lp-1-compare-js" */),
  "component---src-pages-lp-1-create-convert-jsx": () => import("./../../../src/pages/lp1/create-convert.jsx" /* webpackChunkName: "component---src-pages-lp-1-create-convert-jsx" */),
  "component---src-pages-lp-1-installation-instructions-js": () => import("./../../../src/pages/lp1/installation-instructions.js" /* webpackChunkName: "component---src-pages-lp-1-installation-instructions-js" */),
  "component---src-pages-lp-1-jsx": () => import("./../../../src/pages/lp1.jsx" /* webpackChunkName: "component---src-pages-lp-1-jsx" */),
  "component---src-pages-lp-1-merge-split-jsx": () => import("./../../../src/pages/lp1/merge-split.jsx" /* webpackChunkName: "component---src-pages-lp-1-merge-split-jsx" */),
  "component---src-pages-lp-1-optimize-secure-jsx": () => import("./../../../src/pages/lp1/optimize-secure.jsx" /* webpackChunkName: "component---src-pages-lp-1-optimize-secure-jsx" */),
  "component---src-pages-merge-split-jsx": () => import("./../../../src/pages/merge-split.jsx" /* webpackChunkName: "component---src-pages-merge-split-jsx" */),
  "component---src-pages-optimize-secure-jsx": () => import("./../../../src/pages/optimize-secure.jsx" /* webpackChunkName: "component---src-pages-optimize-secure-jsx" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-purchased-jsx": () => import("./../../../src/pages/purchased.jsx" /* webpackChunkName: "component---src-pages-purchased-jsx" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-blog-filter-author-js": () => import("./../../../src/templates/blog-filter-author.js" /* webpackChunkName: "component---src-templates-blog-filter-author-js" */),
  "component---src-templates-blog-filter-category-js": () => import("./../../../src/templates/blog-filter-category.js" /* webpackChunkName: "component---src-templates-blog-filter-category-js" */),
  "component---src-templates-blog-filter-featured-js": () => import("./../../../src/templates/blog-filter-featured.js" /* webpackChunkName: "component---src-templates-blog-filter-featured-js" */),
  "component---src-templates-blogpost-js": () => import("./../../../src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */)
}

