// import React from "react";
// import { useContext } from "react";
import styled from "styled-components";
// import { GlobalContext } from "../../Contexts/GlobalContext";
export const Button = styled.button`
  margin: ${({ margin }) => `${margin}`};
  margin-bottom: ${({ mb }) => `${mb}px`};
  width: ${(props) => (props.width ? props.width : "auto")};
  max-width: ${(props) => (props.MaxWidth ? props.MaxWidth : "auto")};
  max-height: ${(props) => (props.MaxHeight ? props.MaxHeight : "auto")};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  background: ${(props) => (props.background ? props.background : "#3571ce")};
  border: 2px solid #3571ce;
  border-radius: 8px;
  border-color: #3571ce;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: ${(props) => (props.color ? props.color : "white")};
  cursor: pointer;

  :hover {
    ${(props) => props.textHover && `color: #79a2df;`}
    background: ${(props) =>
      props.backgroundHover ? props.backgroundHover : "#2177f8"};
    border-color: ${(props) =>
      props.borderHover ? props.borderHover : "#2177f8"};
  }
`;

// function Button({ children }, props) {
//   const { urlParameter } = useContext(GlobalContext);
//   const queryString =
//     typeof window !== "undefined" ? window.location.search : "";
//   const url = typeof window !== "undefined" ? window.location.pathname : "";

//   function addParametersToUrl() {
//     if (urlParameter !== "" && queryString.length === 0) {
//       window.history.pushState(
//         { path: url + "?" + urlParameter },
//         "",
//         url + "?" + urlParameter
//       );
//       // navigate(url + "?" + urlParameter);
//     }
//   }

//   return (
//     <ButtonStyle {...props} onClick={addParametersToUrl}>
//       {children}
//     </ButtonStyle>
//   );
// }

// export default Button;
