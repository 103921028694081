import styled from "styled-components";
import { Link } from "gatsby";

export const NavMobileContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const LinkHolderMobile = styled.div`
  position: absolute;
  top: 70px;
  width: 100%;
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  align-items: flex-start;
  z-index: 20px;
`;

export const ButtonHolder = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 250px;
  margin-bottom: 40px;
`;

export const Links = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  padding-left: 4%;
  transform: translateY(-88px);
`;
export const AncerLink = styled.a`
  color: white;
  text-decoration: none;
  &:focus,
  &:visited {
    outline: none;
    color: white;
  }
`;

export const FeatureHolder = styled.div`
  margin-top: 15px;
  position: absolute;
  background-color: white;
  width: max-content;
  padding-top: 10px;
  z-index: 1;
  overflow: hidden;
  max-height: ${({ open }) => (open ? "500px" : "0")};
  transition: max-height 0.3s ease, opacity 0.3s ease;
`;

export const FeatureItem = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 5px;

  :hover {
    color: #3571ce;
    background-color: #f5f6fc;
  }
`;

export const StyledLinkDropdown = styled(Link)`
  font-size: 18px;
  line-height: 24px;
  color: #27313b;
  text-decoration: none;
  cursor: pointer;

  background-color: ${({ activeTab }) => (activeTab ? "#f5f6fc" : "#fffff")};
  color: ${({ active }) => (active ? "#3571ce" : "#27313b")};

  :hover {
    color: #3571ce;
    background-color: #f5f6fc;
  }
`;
