import React, { useContext, useEffect, useState } from "react";
import "./GlobalCSS.css";
import { Main, Wrapper } from "./LayoutStyled";
import Navbar from "../GlobalCommponents/Navbar/Navbar";
import Footer from "../GlobalCommponents/Footer/Footer";
import { GlobalContext } from "../../Contexts/GlobalContext";
import NavbarLp1 from "../GlobalCommponents/Navbar/NavbarLp1";
import { useLocation } from "@reach/router";
import FooterLP from "../GlobalCommponents/Footer/FooterLP";
import MacPopup from "../GlobalCommponents/MacPopup/macPopup";
import NavbarCheckout from "../GlobalCommponents/Navbar/NavbarCheckout";

const Layout = ({ children }) => {
  const { urlParameter, setUrlParameter, macOpen } = useContext(GlobalContext);
  const url = typeof window !== "undefined" ? window.location.pathname : "";
  const query = typeof window !== "undefined" ? window.location.search : "";
  const location = useLocation();

  console.log("layout", location.pathname);

  useEffect(() => {
    const queryString = window.location.search;
    let urlParams = "";
    if (urlParameter === "" && queryString.length > 2) {
      urlParams = new URLSearchParams(queryString);
      setUrlParameter(urlParams.toString());
    } else {
      return;
    }
  }, [url, urlParameter, setUrlParameter, query]);

  useEffect(() => {
    console.log("macOpen", macOpen);
  }, [macOpen]);

  const _decorateURL = (url) => {
    if (window.clsid) {
      var _pl =
        "&clientId=" +
        window.clsid.client_id +
        "&sessionId=" +
        window.clsid.session_id;
      return url + "?" + _pl;
    }
    return url;
  };

  useEffect(() => {
    window.decorateURL = _decorateURL;
  }, []);

  return (
    <Wrapper>
      <MacPopup />
      {!location.pathname.includes("lp1") &&
        !location.pathname.includes("checkout") && <Navbar />}
      {location.pathname.includes("lp1") && <NavbarLp1 location={location} />}
      {location.pathname.includes("checkout") && (
        <NavbarCheckout location={location} />
      )}

      <Main>{children}</Main>
      {!location.pathname.includes("lp1") && <Footer></Footer>}
      {location.pathname.includes("lp1") && (
        <FooterLP location={location}></FooterLP>
      )}
    </Wrapper>
  );
};

export default Layout;
