import styled from "styled-components";
import { Link } from "gatsby";

export const NavWrapper = styled.div`
  background: #ffffff;
  /* border-bottom: 1px solid #dcdcdc; */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`;

export const NavContainer = styled.div`
  max-width: 1444px;
  width: 90vw;
  height: 82px;
  margin: 0 auto;
  padding: 20px 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavLinkHolder = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: 10px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledLink = styled(Link)`
  font-size: 18px;
  line-height: 24px;
  color: #27313b;
  text-decoration: none;
  padding: 15px;
  cursor: pointer;

  background-color: ${({ activeTab }) => (activeTab ? "#f5f6fc" : "#fffff")};
  color: ${({ active }) => (active ? "#3571ce" : "#27313b")};

  :hover {
    color: #3571ce;
    background-color: #f5f6fc;
  }
`;

export const AncerLink = styled.a`
  color: white;
  text-decoration: none;

  &:focus,
  &:visited {
    outline: none;
    color: white;
  }
`;

export const HamburgerHolder = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const DropdownHolder = styled.div`
  position: relative;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 45px;

  width: max-content;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  box-shadow: 0px 17px 35px -7px rgba(50, 50, 93, 0.07),
    0px 10px 20px -10px rgba(0, 0, 0, 0.07);
  opacity: ${({ active }) => (active ? 1 : 0)};
  visibility: ${({ active }) => (active ? "visible" : "hidden")};
  transition: opacity 0.3s ease, visibility 0.3s ease;
`;

export const FeatureHolder = styled.div`
  display: flex;
  align-items: center;
  gap: 9.11px;
  cursor: pointer;
  padding: 15px;
  background-color: ${({ activeTab }) => (activeTab ? "#f5f6fc" : "#ffffff")};

  :hover {
    background-color: #f5f6fc;
  }
`;

export const StyledLinkDropdown = styled(Link)`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;

  color: #333333;
  text-decoration: none;

  :hover {
    color: rgba(4, 3, 3, 0.672);
  }
`;
