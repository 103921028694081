import React from "react";
import Layout from "./src/commponents/Layout/Layout";
import GlobalContextProvider from "./src/Contexts/GlobalContext";
import { Helmet } from "react-helmet";
import { navigate } from "gatsby";

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  console.log("new pathname", location);
  console.log("old pathname", prevLocation);

  if (prevLocation && !location.pathname.includes("purchased")) {
    const searchParams = new URLSearchParams(prevLocation.search);
    const searchParamsNew = new URLSearchParams(location.search);

    if ([...searchParams].length > 0 && [...searchParamsNew].length === 0) {
      navigate(`${location.pathname}?${searchParams}`, {
        replace: true,
      });
    }
  }
};

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>;
};

export const wrapPageElement = ({ element, location }) => {
  return (
    <>
      {" "}
      <Helmet>
        <script type="text/javascript">
          var baseurl = "pdfwriter.com/lp2";
        </script>
        <script
          async
          type="text/javascript"
          src="https://track.pdfpro10.com/static/tracking.js"
        ></script>
      </Helmet>{" "}
      <Layout location={location}>{element}</Layout>
    </>
  );
};
