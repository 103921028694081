import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import { NavContainer, NavWrapper, NavLinkHolder } from "./NavbarStlyled";
import PDFLogo from "../../../Icons/PDFWriterLogo.svg";
import styled from "styled-components";
import CountryDropdown from "../../Checkout/CountryDropdown/CountryDropdown";

const NavWrapperCheckout = styled(NavWrapper)`
  border-bottom: 1px solid #cfe2ff;
`;

const NavLinkHolderCheckout = styled(NavLinkHolder)`
  @media (max-width: 768px) {
    display: flex;
  }
`;
const NavbarCheckout = () => {
  const [width, setWidth] = useState(0);

  return (
    <NavWrapperCheckout>
      <NavContainer>
        <Link to="/">
          <PDFLogo />
        </Link>

        <NavLinkHolderCheckout>
          <CountryDropdown setWidth={setWidth} />
        </NavLinkHolderCheckout>
      </NavContainer>
      {/* {menuIsOpen ? (
        <NavbarMobile ToggleNavbarHandler={ToggleNavbarHandler} />
      ) : null} */}
    </NavWrapperCheckout>
  );
};

export default NavbarCheckout;
