import styled from "styled-components";
import { Link } from "gatsby";

export const Wrapper = styled.div`
  border-style: solid;
  border-color: #aaaaaa8e;
  border-width: 1px;
  background-color: #222222;
`;

export const Container = styled.div`
  margin: 0 auto;
  padding-top: 80px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 1440px;
`;

export const ContainerLP = styled(Container)`
  padding-top: 40px;
  padding-bottom: 25px;
`;

export const PaymentHolder = styled.div`
  width: 92px;
  height: 100px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
export const Column = styled.div`
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
export const Img = styled.img`
  width: 34px;
  height: 23px;
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: row;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-basis: calc(100% / 6);

  @media (max-width: 550px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;

export const FlexBoxLP = styled(FlexBox)`
  @media (max-width: 1440px) {
    width: 90vw;
    margin: auto;
  }
`;

export const FlexBoxItemHolder = styled.div`
  display: flex;
  gap: 32px;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

export const ItemHolder = styled.div`
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 210px;
  a {
    line-height: 18px;
  }

  @media (max-width: 1025px) {
    flex-basis: calc(100% / 3);
    padding-top: 30px;
    padding-right: 0;
  }
  @media (max-width: 830px) {
    flex-basis: calc(100% / 2);
  }
`;

export const LogoHolder = styled.div`
  margin-top: 10px;
  width: 120px;
  height: 32px;
`;

export const CopyRight = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #dcdcdc;
  margin: 0 auto;
  padding-bottom: 23px;
  text-align: center;
`;

export const ShortText = styled.p`
  padding-top: 55px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #dcdcdc;

  padding-top: 55px;
  padding-bottom: 23px;
`;

export const ShortTextLp = styled(ShortText)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 1440px) {
    width: 90vw;
    margin: auto;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #ffff;
`;
export const Description = styled.p`
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #999999;
`;
export const AncerLink = styled.a`
  font-weight: ${(props) => props.weight || 400};
  font-size: 14px;
  line-height: 30px;
  color: ${(props) => props.color || "#ffff"};

  text-decoration: none;

  :hover {
    cursor: pointer;
  }
`;

export const PDFLink = styled.a`
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #999999;
  text-decoration: underline;
  :hover {
    cursor: pointer;
  }
`;

export const StyledLink = styled(Link)`
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  color: #ffff;
  text-decoration: none;

  :hover {
    cursor: pointer;
  }
`;

export const StyledLinkLp = styled(StyledLink)`
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: rgb(220, 220, 220);
  margin-bottom: 15px;
`;
export const PDFAssociationHolder = styled.div`
  width: 64px;
  height: 30px;
`;
