import React from "react";
import { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import Close from "./Icons/close-icon-popup.svg";
import { Link, navigate } from "gatsby";
import AppleIcon from "./Icons/apple.svg";
import { GlobalContext } from "../../../Contexts/GlobalContext";
import { AncerLink } from "../Navbar/NavbarStlyled";
import { Pages } from "../../../constants/constants";

const MacPopupStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props?.macOpen === false && `display: none;`}
`;

const Holder = styled.div`
  width: 90%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  padding: 32px;
  gap: 16px;
  box-shadow: 0px 50px 100px -20px rgba(50, 50, 93, 0.25),
    0px 30px 60px -30px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  svg {
    margin-left: auto;
    cursor: pointer;
    max-width: 12px;
    max-height: 12px;
    color: #333333;
  }
`;

const InnerHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  padding: 32px;
  gap: 16px;
  @media (max-width: 480px) {
    padding: 0;
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 25px;
  line-height: 33px;
  letter-spacing: -0.03em;
  color: #333333;
  margin-bottom: 8px;
`;

const Row = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.03em;

  a {
    display: inline-block;
    color: #6361ff;
  }
`;

const PartnerButton = styled.button`
  all: unset;
  background: #00ca42;
  border-radius: 30px;
  padding: 14px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #ffffff;
  cursor: pointer;
  @media (max-width: 880px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    font-size: 16px;
    line-height: 16px;
  }
  @media (max-width: 380px) {
    padding: 10px 12px;

    font-size: 14px;
    line-height: 14px;
  }
`;

const ContinueButton = styled.button`
  all: unset;
  border: 1px solid #00ca42;
  border-radius: 30px;
  padding: 14px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  letter-spacing: -0.03em;
  color: #00ca42;
  cursor: pointer;
  @media (max-width: 880px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    font-size: 16px;
    line-height: 16px;
  }
  @media (max-width: 380px) {
    padding: 10px 12px;

    font-size: 14px;
    line-height: 14px;
  }
`;

const ButtonRow = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  gap: 20px;
`;

const IconHolder = styled.div`
  width: 100px;
  height: 100px;
  margin-bottom: 24px;
  svg {
    min-width: 100%;
    min-height: 100%;
  }
`;

const AncerLinkPopup = styled(AncerLink)`
  ${(props) => props?.show === false && `opacity: 0;`}
  ${(props) => props?.show === false && `max-width: 0;`}
  ${(props) => props?.show === false && `max-height: 0;`}
  ${(props) => props?.show === false && `height: 0 !important;`}
  ${(props) => props?.show === false && `width: 0 !important;`}
  ${(props) => props?.show === false && `padding: 0 !important;`}

  button {
    ${(props) => props?.show === false && `max-width: 0;`}
    ${(props) => props?.show === false && `max-height: 0;`}
  ${(props) => props?.show === false && `padding: 0 !important;`}
  }
`;

function MacPopup({ location }) {
  const [type, setType] = useState("");

  const [link, setLink] = useState("");

  const { setMacOpen, macOpen, isMac, linkType } = useContext(GlobalContext);

  useEffect(() => {
    var isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
    var isiPhone = /iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    // Usage
    if (isMac) {
      setType("Mac");
      setLink("https://readdle.8kpa2n.net/c/4477090/763057/11051");
    }

    if (isiPhone) {
      console.log("User is on an iPhone");
      setType("iPhone");
      setLink("https://readdle.8kpa2n.net/c/4477090/763057/11051");
    }

    // if (isMac || isiPhone) {
    //   const popup = document.getElementById("MacPopup");
    //   if (popup && window.displayed !== true) {
    //     popup.style.display = "flex";
    //     window.displayed = true;
    //   }
    // }
  }, [location]);

  return isMac ? (
    <MacPopupStyle id="MacPopup" macOpen={macOpen}>
      <Holder>
        <Close onClick={() => setMacOpen(false)} />
        <InnerHolder>
          <IconHolder>
            <AppleIcon />
          </IconHolder>

          <Title>Welcome {type} User!</Title>
          <Row>
            <b>
              Unfortunately, PDF Writer isn’t compatible with Mac at this time.
            </b>{" "}
            However, for your benefit, we’ve partnered a great PDF editor for
            Mac, from a company we trust. You’ll be in good hands.
          </Row>
          <ButtonRow>
            <PartnerButton onClick={() => navigate(link)}>
              Visit Mac Partner page
            </PartnerButton>

            <AncerLinkPopup
              href={Pages.checkout}
              target="_blank"
              rel="noopener noreferrer"
              className="tracker"
              show={linkType === Pages.checkout}
            >
              <ContinueButton onClick={() => setMacOpen(false)}>
                Continue to PDF Writer Checkout
              </ContinueButton>
            </AncerLinkPopup>

            <AncerLinkPopup
              target="_blank"
              rel="noopener noreferrer"
              className="tracker"
              show={
                linkType ===
                "https://gtw.pdfpro10.com/product/normal/pdfwriter_nix/trial"
              }
            >
              <ContinueButton
                onClick={() => {
                  setMacOpen(false);
                }}
              >
                Remain on the pdfwriter.com website
              </ContinueButton>
            </AncerLinkPopup>
          </ButtonRow>
        </InnerHolder>
      </Holder>
    </MacPopupStyle>
  ) : null;
}

export default MacPopup;
