import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
  background: #ffffff;
`;

export const Main = styled.div`
  margin-top: 82px;
  flex: 1;
`;
