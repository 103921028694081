import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import {
  NavContainer,
  NavWrapper,
  NavLinkHolder,
  StyledLink,
  AncerLink,
  HamburgerHolder,
  DropdownHolder,
  DropdownMenu,
  FeatureHolder,
  StyledLinkDropdown,
} from "./NavbarStlyled";
import PDFLogo from "../../../Icons/PDFWriterLogo.svg";
import HamburgerOpen from "../../../Icons/Hamburger.svg";
import HamburgerClosed from "../../../Icons/CloseHamburger.svg";
import Button from "../../ReusableCommponents/ButtonNew";
import NavbarMobile from "../NavbarMobile/NavbarMobile";
import Icon1 from "./Icons/create-convert.svg";
import Icon2 from "./Icons/merge-split.svg";
import Icon3 from "./Icons/annotate-markup.svg";
import Icon4 from "./Icons/optimize-secure.svg";
import { GlobalContext } from "../../../Contexts/GlobalContext";
import { Pages } from "../../../constants/constants";

const Navbar = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);
  const location = useLocation();
  const dropdownRef = useRef(null);
  const { isMac } = useContext(GlobalContext);

  const handleDropdownMouseEnter = () => {
    clearTimeout(timeoutId);
    setDropdownOpen(true);
  };
  const handleDropdownMouseLeave = () => {
    const id = setTimeout(() => {
      setDropdownOpen(false);
    }, 1000);
    setTimeoutId(id);
  };

  const CloseDropDownHandler = () => {
    setDropdownOpen(false);
  };

  const ToggleNavbarHandler = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  useEffect(() => {
    if (location.pathname === "/create-convert/") {
      setActiveTab(1);
    } else if (location.pathname === "/merge-split/") {
      setActiveTab(2);
    } else if (location.pathname === "/annotate-markup/") {
      setActiveTab(3);
    } else if (location.pathname === "/optimize-secure/") {
      setActiveTab(4);
    } else {
      setActiveTab(0);
      setDropdownOpen(false);
    }
  }, [location]);

  return (
    <NavWrapper>
      <NavContainer>
        <Link to="/">
          <PDFLogo />
        </Link>
        <NavLinkHolder>
          <DropdownHolder ref={dropdownRef}>
            <div
              onMouseEnter={handleDropdownMouseEnter}
              onMouseLeave={handleDropdownMouseLeave}
            >
              <StyledLink active={dropdownOpen}>Features</StyledLink>
            </div>
            <DropdownMenu
              active={dropdownOpen}
              onMouseEnter={handleDropdownMouseEnter}
              onMouseLeave={handleDropdownMouseLeave}
            >
              <StyledLinkDropdown to="/create-convert">
                <FeatureHolder
                  onClick={CloseDropDownHandler}
                  activeTab={activeTab === 1}
                >
                  <Icon1 />
                  Create & Convert
                </FeatureHolder>
              </StyledLinkDropdown>

              <StyledLinkDropdown to="/merge-split">
                <FeatureHolder
                  onClick={CloseDropDownHandler}
                  activeTab={activeTab === 2}
                >
                  <Icon2 />
                  Merge and Split
                </FeatureHolder>
              </StyledLinkDropdown>
              <StyledLinkDropdown to="/annotate-markup">
                <FeatureHolder
                  onClick={CloseDropDownHandler}
                  activeTab={activeTab === 3}
                >
                  <Icon3 />
                  Annotate and Markup
                </FeatureHolder>
              </StyledLinkDropdown>
              <StyledLinkDropdown to="/optimize-secure">
                <FeatureHolder
                  onClick={CloseDropDownHandler}
                  activeTab={activeTab === 4}
                >
                  <Icon4 />
                  Optimize & Secure
                </FeatureHolder>
              </StyledLinkDropdown>
            </DropdownMenu>
          </DropdownHolder>
          <StyledLink to="/compare">Compare</StyledLink>
          <StyledLink to="/blog">Resources</StyledLink>
          {!isMac ? (
            <AncerLink
              href={Pages.checkout}
              target="_blank"
              rel="noopener noreferrer"
              className="tracker"
            >
              <Button width={"100%"}>Buy Now</Button>
            </AncerLink>
          ) : (
            <Button width={"100%"} link={Pages.checkout} className="tracker">
              Buy Now
            </Button>
          )}
        </NavLinkHolder>
        <HamburgerHolder onClick={ToggleNavbarHandler} menuState={menuIsOpen}>
          {!menuIsOpen ? <HamburgerOpen /> : <HamburgerClosed />}
        </HamburgerHolder>
      </NavContainer>
      {menuIsOpen ? (
        <NavbarMobile ToggleNavbarHandler={ToggleNavbarHandler} />
      ) : null}
    </NavWrapper>
  );
};

export default Navbar;
