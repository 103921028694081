import React from "react";
import {
  ContainerLP,
  Wrapper,
  FlexBoxLP,
  Description,
  ItemHolder,
  Title,
  StyledLinkLp,
  AncerLink,
  LogoHolder,
  ShortTextLp,
  PDFAssociationHolder,
  FlexBoxItemHolder,
  PDFLink,
} from "./FooterStyled";
import PDFWriterLogo from "../../../Icons/PDFWriterLogoWhiteText.svg";
import Association from "../../../images/PDFAsociation.png";

const FooterLP = ({ location }) => {
  return (
    <Wrapper>
      <ContainerLP>
        <FlexBoxLP>
          <ItemHolder>
            {/* <Title>PDF Pro Software Inc.</Title> */}
            {/* <Description>
              hello@pdfpro.com 1383 W. 8th Avenue Vancouver, B.C. V6H 3W4,
              Canada
            </Description> */}
            <LogoHolder>
              <PDFWriterLogo />
            </LogoHolder>
            <PDFLink href="mailto: hello@pdfpro.com">hello@pdfpro.com</PDFLink>
          </ItemHolder>
          {/* <FlexBoxItemHolder> */}
          <ItemHolder>
            <StyledLinkLp to={`/terms-and-conditions`}>
              Terms and Conditions
            </StyledLinkLp>
            <StyledLinkLp to={`/cookies`}>Cookie Policy</StyledLinkLp>
          </ItemHolder>
          <ItemHolder>
            <StyledLinkLp to={`/privacy`}>Privacy Policy</StyledLinkLp>
            <StyledLinkLp to={`/dpa`}>DPA</StyledLinkLp>
          </ItemHolder>
          <ItemHolder>
            <StyledLinkLp to={`/eula`}>EULA</StyledLinkLp>
          </ItemHolder>
        </FlexBoxLP>
        <ShortTextLp>
          PDF Writer is a desktop software for Windows distributed by PDF Pro
          Software Inc. The software "PDF Writer" and the company "PDF Pro
          Software Inc." are in no way affiliated with Adobe. The PDF file
          format is an open file format published under ISO 32000-1:2008
          <span>
            ©2017-{new Date().getFullYear()}, PDF Pro Software Inc. All rights
            reserved.
          </span>
        </ShortTextLp>
      </ContainerLP>
    </Wrapper>
  );
};

export default FooterLP;
