import React, { useEffect, useState } from "react";
import styled from "styled-components";

const FlagStyle = styled.div`
  max-width: 48px;
  max-height: 32px;
  min-height: 32px;

  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loading = styled.div`
  min-width: 43px;
  max-height: 32px;
  min-height: 32px;
  background: #f2f2f2;
`;

const Flag = ({ tag }) => {
  // Dynamic import of SVG flag based on countryCode
  const [FlagComponent, setFlag] = useState(Loading);

  useEffect(() => {
    let FC = null;
    try {
      FC = React.lazy(() => import(`../Icons/flags/${tag}.svg`));
      setFlag(FC);
    } catch (error) {
      // Use the NotFoundFlag if the flag for the given country doesn't exist
      setFlag(Loading);
    }
  }, [tag]);

  return (
    <div>
      <FlagStyle>
        <React.Suspense fallback={<Loading />}>
          <FlagComponent />
        </React.Suspense>
      </FlagStyle>
    </div>
  );
};

export default Flag;
