import React from "react";
import {
  Container,
  Wrapper,
  FlexBox,
  Description,
  ItemHolder,
  Title,
  StyledLink,
  AncerLink,
  LogoHolder,
  ShortText,
  PDFAssociationHolder,
  FlexBoxItemHolder,
  PDFLink,
} from "./FooterStyled";
import PDFWriterLogo from "../../../Icons/PDFWriterLogoWhiteText.svg";
import Association from "../../../images/PDFAsociation.png";

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <FlexBox>
          <ItemHolder>
            <Title>PDF Pro Software Inc.</Title>
            <PDFLink href="mailto: hello@pdfpro.com">hello@pdfpro.com</PDFLink>
            <Description>
              hello@pdfpro.com 1383 W. 8th Avenue Vancouver, B.C. V6H 3W4,
              Canada
            </Description>
            <LogoHolder>
              <PDFWriterLogo />
            </LogoHolder>
          </ItemHolder>
          <FlexBoxItemHolder>
            <ItemHolder>
              <Title>Policy</Title>
              <StyledLink to="/terms-and-conditions">
                Terms and Conditions
              </StyledLink>
              <StyledLink to="/cookies">Cookie Policy</StyledLink>
              <StyledLink to="/privacy">Privacy Policy</StyledLink>
              <StyledLink to="/dpa">DPA</StyledLink>
              <StyledLink to="/eula">EULA</StyledLink>
            </ItemHolder>
            <ItemHolder>
              <Title>Company</Title>
              <StyledLink to="/about">About Us</StyledLink>
              <StyledLink to="/installation-instructions">
                Installation Instructions
              </StyledLink>
              <AncerLink
                weight={600}
                title="Support"
                href="https://pdfwriter.zendesk.com/hc/en-us/requests/new"
                target="_blank"
                rel="noopener noreferrer"
              >
                Support
              </AncerLink>
              <AncerLink
                color="#999999"
                href="https://pdfa.org/member/pdf-pro-software-inc/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Member of:
              </AncerLink>
              <AncerLink
                href="https://pdfa.org/member/pdf-pro-software-inc/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <PDFAssociationHolder>
                  <img src={Association} alt="PDF Association" />
                </PDFAssociationHolder>
              </AncerLink>
            </ItemHolder>
          </FlexBoxItemHolder>
        </FlexBox>
        <ShortText>
          © 2017-{new Date().getFullYear()}, PDF Pro Software Inc. All rights
          reserved.
        </ShortText>
      </Container>
    </Wrapper>
  );
};

export default Footer;
